import React, { useState } from 'react';
import { css } from '@emotion/css';
import { Button, Label, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2, PanelProps } from '@grafana/data';
import { Settings } from 'types';
import { getExcelFile, resolveVoyages } from 'file-formatting';

export const Panel: React.FC<PanelProps<Settings>> = (props) => {
  const { width, data } = props;
  const { showDetails } = props.options;

  const styles = useStyles2(getStyles(props));

  const [loading, setLoading] = useState(false);
  const [status] = useState<string[]>([]);
  const handleOnClick = async () => {
    setLoading(true);
    let voyages = resolveVoyages(data);
    if (voyages?.length) {
      await getExcelFile(voyages);
    }
    setLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonWrapper}>
        <Button
          icon={loading ? 'fa fa-spinner' : 'download-alt'}
          size={'lg'}
          fullWidth={width > 150}
          onClick={handleOnClick}
        >
          {width > 150 ? (loading ? 'Building' : 'Download') : null}
        </Button>
      </div>
      {showDetails ? (
        <div className={styles.statusWrapper}>
          {status.map((e, idx) => (
            <Label key={idx}>{e}</Label>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const getStyles = (props: PanelProps<any>) => (theme: GrafanaTheme2) => {
  const { height } = props;
  return {
    buttonWrapper: css`
      display: flex;
      justify-content: center;
      margin-bottom: 2px;
    `,
    wrapper: css`
      overflow: hidden;
      max-height: ${height}px;
    `,
    statusWrapper: css`
      overflow: auto;
      height: ${height - 50}px;
    `,
  };
};
