import { PanelPlugin } from '@grafana/data';
import { Settings } from 'types';
import { Panel } from './Panel';

// ts-prune-ignore-next
export const plugin = new PanelPlugin<Settings>(Panel).setPanelOptions((builder) =>
  builder.addBooleanSwitch({
    path: 'showDetails',
    name: 'Show details',
    defaultValue: false,
    description: 'Show a log while downloading',
  })
);
